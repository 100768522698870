<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <forexTabs />
    <b-row class="history">
      <b-col>
        <b-card>
          <!-- header -->
          <b-row
            align-v="center"
            class="mb-2 pb-1"
            style="border-bottom: 1px solid #ddd"
          >
            <b-col
              class="text-center"
              style="cursor: pointer"
              cols="auto"
              @click="$router.go(-1)"
            >
              <b-col cols="12">
                {{ $t("back") }}
              </b-col>
              <b-col cols="12">
                <feather-icon
                  v-if="$i18n.isRTL"
                  icon="ArrowRightIcon"
                  size="14"
                />
                <feather-icon
                  v-else
                  icon="ArrowLeftIcon"
                  size="14"
                />
              </b-col>
            </b-col>

          </b-row>

          <b-row
            align-h="center"
            class="table-parent"
          >
            <b-col cols="12">
              <b-table
                sticky-header="1000px"
                responsive
                striped
                hover
                :fields="fields"
                :items="items"
              >
                <!-- <template #cell(tp)="data">
                  <span class="bg-success">{{ data.item["tp"] }}
                  </span>
                </template> -->
                <template #cell(close_time)="data">
                  <span class="text-nowrap">
                    {{ data.item["close_time"] }}
                  </span>
                </template>
                <template #cell(open_time)="data">
                  <span
                    class="text-nowrap"
                  >
                    {{ data.item["open_time"] }}
                  </span>
                </template>
              </b-table>
            </b-col>
            <b-col cols="auto">
              <b-pagination
                v-if="totalTransactions > perPageTransactions"
                v-model="currentpageTransactions"
                :total-rows="totalTransactions"
                :per-page="perPageTransactions"
                align="fill"
                size="sm"
                class="mt-2 mb-1"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script >

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import MetatraderApis from '@/modules/forex/service/apis'
import moment from '@/libs/moment'
import forexTabs from './components/forexTabs.vue'

const metatrader = new MetatraderApis()
export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    forexTabs,
  },
  data() {
    return {
      show: false,
      items: [],
      currentpageTransactions: 1,
      totalTransactions: 0,
      perPageTransactions: 30,
    }
  },
  computed: {
    fields() {
      return [
        {
          label: this.$t('forex.open-price'),
          key: 'open_price',
        },
        {
          label: this.$t('forex.open-time'),
          key: 'open_time',
        },
        {
          label: this.$t('forex.close-price'),
          key: 'close_price',
        },
        {
          label: this.$t('forex.close-time'),
          key: 'close_time',
        },

        {
          label: this.$t('forex.order'),
          key: 'order',
        },
        {
          label: this.$t('forex.profit'),
          key: 'profit',
        },
        {
          label: 'Tp',
          key: 'tp',
          tdClass: 'nameOfTheTp',
        },
        {
          label: 'Sl',
          key: 'sl',
          tdClass: 'nameOfTheSl',
        },
        {
          label: this.$t('forex.symbol'),
          key: 'symbol',
        },

        {
          label: this.$t('type'),
          key: 'type',
        },
        {
          label: this.$t('forex.volume'),
          key: 'volume',
        },
      ]
    },
  },
  watch: {
    currentpageTransactions(val) {
      if (val) {
        this.getData()
      }
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      await metatrader.tradeHistory(this.$route.params.platform, this.$route.params.id, {
        current_page: this.currentpageTransactions,
        per_page: this.perPageTransactions,
      }).then(res => {
        this.items = res.data.results.data
        this.totalTransactions = res.data.results.paginate.total
        this.currentpageTransactions = res.data.results.paginate.current_page
        this.perPageTransactions = res.data.results.paginate.per_page
        this.show = false
      }).catch((err => {
        console.log(err)
        this.show = false
      }))
    },
    // getFormattedDate(date) {
    //   console.log(date)

    //   return moment(date).format('YYYY-MM-DD')
    // },
  },
}

</script>

<style scoped>
.history::v-deep .nameOfTheTp{
  background-color: rgb(106, 221, 106) !important;
  color: white;
  /* text-align: center; */
}
.history::v-deep .nameOfTheSl{
  background-color: rgb(219, 119, 119);
  color: white;
  /* text-align: center; */

}
.table-parent::v-deep tbody td {
    width: 50px !important;
    padding: 0 5px !important;
  }
  .table-parent::v-deep thead th{
    padding: 5px !important;
    width: 50px !important;

  }

</style>
